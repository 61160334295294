import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortname', pure: false
})
export class ShortnamePipe implements PipeTransform {

  transform(value: string): string {
    let fullName = value.split(' ');
    let setdata = [];
    let convervalue = '';
    if (fullName.length >= 2) {
      for (let i = 0; i < fullName.length; i++) {
        if (i == fullName.length - 1) { continue; }
        let data = fullName[i].charAt(0);
        setdata.push(data)
      }
      convervalue = setdata.join(" ") + ' ' + fullName.pop();
    } else {
      convervalue = value
    }
    let result = convervalue.toUpperCase()
    return result;
  }

}


@Pipe({
  name: 'indianCurrency'
})
export class IndiaCurrencyPipe implements PipeTransform {

  transform(amount: any, args?: any): string {
    if (amount !== null && !isNaN(amount)) {
      const currencySymbol = '₹ ';
      const intergerValue = Math.floor(amount).toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
      return currencySymbol + intergerValue;
    }
  }

}
