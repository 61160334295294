import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Route } from '@angular/router'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  varShowMenu:string='1';

  constructor(public objNavCtrll : NavController) {
    console.log('innn')
   }

  ngOnInit() {
    if(localStorage.getItem('footer')){
      this.varShowMenu = localStorage.getItem('footer');
      console.log(this.varShowMenu)
    }
  }
  

  opendata(value:any, page:string){    
    this.varShowMenu = value;
    this.objNavCtrll.navigateRoot('/'+page)
    localStorage.setItem('footer',value)
  }

  ionViewDidEnter(){
    console.log('in')
    this.varShowMenu = localStorage.getItem('footer');
    console.log(this.varShowMenu)
  }
}
