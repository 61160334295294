import { Component, OnInit, HostListener } from '@angular/core';
import { NavController, MenuController, Platform, ModalController } from '@ionic/angular';
import { CommonserviceService } from '../../services/commonservice.service';
import {Config } from "../../config/config";
import { AddCashPage } from '../../pages/add-cash/add-cash.page';
import { Location } from '@angular/common';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {

  userData: any;
  selectedImage: any = '../assets/4.svg';
  result: any;
  copyrights: string = '';
  moreSettings: boolean = false;
  deviceWidth: any;
  isBrowserMenu = '2';
  val
  @HostListener('window:resize', ['$event']) getScreenSize(event?) {
    this.deviceWidth = window.innerWidth;
    console.log(this.deviceWidth, this.isBrowserMenu);
    if(this.conf.value == 0)
    {
      this.objModal.dismiss();
      this.conf.value = 2;
    }else if( this.conf.value == 1 || this.deviceWidth > 500){
      if(localStorage.getItem('ismenu') == '1')
      {
        this.navCtrl.navigateBack('/dashboard');
      }
      this.conf.value = 2;
    }
  }
  constructor(public commonService: CommonserviceService, public navCtrl: NavController, public menu: MenuController,
    public platform: Platform, public objModal: ModalController,public conf:Config, public location:Location) {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    localStorage.setItem('ismenu','1');
  }

  async ngOnInit() {
    this.deviceWidth = window.innerWidth;
    let elemnt = await this.objModal.getTop();
    console.log(elemnt);
    this.menuOpened();
    if(this.conf.value == 0)
    {
      const modal ={
          modal:true,
          desc:'fake state of modal'
      }
      history.pushState(modal,null)
    }
  }
  ionViewDidLeave()
  {
    localStorage.setItem('ismenu','2');
  }
  ngOnDestroy(){
    if(window.history.state.modal){
      history.back();
    }
  }

  async addCash() {
    this.dissableBackevent();
    localStorage.setItem('addcashFromContest','0');
    const modal = await this.objModal.create({
      component: AddCashPage,
      cssClass: 'add-cash-modal'
    });
   return await modal.present();
  }

  wallet() {
    localStorage.setItem('footer', '3')
    this.dissableBackevent();
    this.navCtrl.navigateForward("/my-account")
  }
  logout() {
    this.dissableBackevent();
    this.commonService.logout();
  }
  menuOpened() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.commonService.commonApiService('get', 'profile', '').then((result: any) => {
      this.result = result;
      this.selectedImage = result.data.profile_image;
      this.copyrights = result.copyright;
      console.log(this.selectedImage);
    });
    // let data = {"keys" : ['copyright']};
    // this.commonService.commonApiService('post','getSettingsContent',data).then((crights:any)=>{
    //  
    // });
  }
  profile() {
    localStorage.setItem('footer', '4')
    this.dissableBackevent();
    this.navCtrl.navigateForward('/personal-details');
  }
  
 @HostListener('window:popstate',['$event'])
  dissableBackevent() {
    localStorage.setItem('ismenu','2');
    if (this.deviceWidth > 500 || this.conf.value == 1) {
      console.log(this.conf);
        this.navCtrl.navigateBack('/dashboard');
      // this.location.back();
    }
    else { 
      this.objModal.dismiss(); 
    }
  }
  more() {
    // this.dissableBackevent();
    // this.navCtrl.navigateForward('/more');
    this.moreSettings = (this.moreSettings == true) ? false : true;
  }
  moreSetting(type) {
    this.dissableBackevent();
    this.navCtrl.navigateForward('/moresettings?type=' + type);
  }
  settings() {
    this.dissableBackevent();
    this.navCtrl.navigateForward('/reset-password?type=1');
  }
}
